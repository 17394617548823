import {
  Grid,
  Box,
  Autocomplete,
  Avatar,
  TextField,
  Chip,
  CircularProgress
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

function BrandAutocomplete({
  label,
  placeholder,
  loading,
  options,
  fullWidth,
  multiple,
  value,
  onChange,
  name,
  disabled,
  noLabel = false,
  xs,
  md,
  required = false,
  ...rest
}) {
  const { t } = useTranslation();

  const getDefaultValue = useCallback(() => {
    return multiple
      ? options?.filter((option) => value?.includes(option?.value))
      : options?.find((el) => el?.value === value);
  }, [options, value]);

  const [_value, _setValue] = useState(multiple ? [] : {});

  const _onChange = (_, selectedValue) => {
    _setValue(selectedValue);

    const values = multiple
      ? selectedValue?.map((el) => el?.value)
      : selectedValue?.value;

    onChange?.(name, values);
  };

  useEffect(() => {
    _setValue(getDefaultValue());
  }, [options]);

  return (
    <Grid item xs={xs ?? 12} md={md ?? 12}>
      {!noLabel && (
        <Box pb={1}>
          <b>
            {label}
            {required && <span style={{ color: 'red' }}> *</span>} :
          </b>
        </Box>
      )}
      <Autocomplete
        {...rest}
        multiple={multiple}
        filterSelectedOptions={multiple}
        sx={{ m: 0 }}
        onChange={_onChange}
        value={_value}
        options={options}
        disabled={disabled}
        defaultValue={getDefaultValue()}
        renderOption={(props, o) => (
          <li {...props} key={o.value}>
            {o.avatar && (
              <Avatar
                sx={{
                  mr: 1
                }}
                src={o.avatar}
              />
            )}
            {o.label}
          </li>
        )}
        getOptionLabel={(o) => o.label}
        renderInput={(params) => (
          <>
            {loading ? (
              <CircularProgress />
            ) : (
              <TextField
                {...params}
                variant="outlined"
                name={name}
                required={required}
                fullWidth={fullWidth || true}
                InputLabelProps={{
                  shrink: true
                }}
                placeholder={t(placeholder)}
              />
            )}
          </>
        )}
        renderTags={(members, getTagProps) =>
          members.map((ev, index) => (
            <>
              {loading ? (
                <CircularProgress />
              ) : (
                <Chip
                  key={ev.label}
                  label={ev.label}
                  {...getTagProps({ index })}
                  avatar={ev.avatar && <Avatar src={ev.avatar} />}
                />
              )}
            </>
          ))
        }
      />
    </Grid>
  );
}

const createOption = (label, value) => ({ label, value });

const createOptionsFromArr = (arr, labelString, valueString) =>
  arr?.flat()?.map((el) =>
    createOption(_.get(el, labelString, ''), _.get(el, valueString, ''))
  );

export { createOption, createOptionsFromArr };

export default BrandAutocomplete;
