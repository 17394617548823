import { Box, Card, Link, Typography, styled, Button } from '@mui/material';

const FooterWrapper = styled(Card)(
  ({ theme }) => `
        border-radius: 0;
        margin-top: ${theme.spacing(4)};
`
);

function Footer() {
  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        p={4}
        display={{ xs: 'block', md: 'flex' }}
        alignItems="center"
        textAlign={{ xs: 'center', md: 'left' }}
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="subtitle1">&copy; 2024 - Ventel</Typography>
        </Box>
        <Box>
          <Button
            variant="text"
            onClick={() => window.open('public/term-of-use', '_blank')}
          >
            Terms of use
          </Button>
          <Button
            variant="text"
            onClick={() => window.open('public/privacy', '_blank')}
          >
            Privacy Policy
          </Button>
        </Box>
        <Typography
          sx={{
            pt: { xs: 2, md: 0 }
          }}
          variant="subtitle1"
        >
          Crafted by{' '}
          <Link
            href="https://ventel.in"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ventel
          </Link>
        </Typography>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
