import { Box, Card, Typography, Container, styled, alpha } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Logo from 'src/components/Logo';
import Scrollbar from 'src/components/Scrollbar';

import useAuth from 'src/hooks/useAuth';
import JWTLogin from '../LoginJWT';

const Content = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    width: 100%;
`
);

const MainContent = styled(Box)(
  () => `
  max-width: 750px;
  display: flex;
  align-items: center;
  justify-content: center;
`
);

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
    position: fixed;
    left: 45vw;
    top: 0;
    height: 100%;
    width: 55vw;
    background: ${theme.colors.primary.main};
    background-size: cover;
`
);

const SidebarContent = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing(6)};
  color: white;
  height: '100%';
  position: 'relative';
  background: linear-gradient(
    to right,
    ${theme.colors.primary.main},
    ${alpha(theme.colors.primary.main, 0.8)},
    transparent
  );
  // &:before {
  //   content: '';
  //   width: 100%;
  //   height: 100%;
  //   background-image: url('static/images/logo/hero_pattern.svg');
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   background-position-Y: bottom;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   z-index: 2;
  // }

`
);

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(52)};
    line-height: 1.4;
    font-weight: 900;
`
);

function LoginCover() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Login - Ventel</title>
      </Helmet>
      <Content>
        <MainContent
          sx={{
            width: { xs: '100%', md: '50vw' }
          }}
        >
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
            maxWidth="sm"
          >
            <Logo />
            <Card
              sx={{
                px: { md: 4, xs: 2 },
                py: 4,
                my: 5
              }}
            >
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  {t('Sign in')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{
                    mb: 3
                  }}
                >
                  {t('Fill in the fields below to sign into your account.')}
                </Typography>
              </Box>
              <JWTLogin />
            </Card>
          </Container>
        </MainContent>
        <SidebarWrapper
          sx={{
            display: { xs: 'none', md: 'flex' }
          }}
        >
          <Scrollbar>
            <SidebarContent>
              <video
                autoPlay
                loop
                muted
                playsInline
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  zIndex: -1
                }}
              >
                <source src="/videos/wavy.webm" type="video/webm" />
                <source src="/videos/wavy.mp4" type="video/mp4" />
              </video>
              <Box
                mt={6}
                sx={{
                  alignItems: 'start',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100vh',
                  pb: 5
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontWeight="500"
                  sx={{
                    mb: 2,
                    color: 'white',
                    width: '50%'
                  }}
                >
                  VENTEL . LIMITLESS CONNECTIVITY
                </Typography>

                <TypographyH1 width="60%" mb={2}>
                  Intelligent & Secure IoT M2M Connectivity
                </TypographyH1>

                <Typography
                  variant="subtitle1"
                  fontSize={20}
                  sx={{
                    mt: 5,
                    mb: 2,
                    color: 'white',
                    width: '50%'
                  }}
                >
                  At VENTEL we specialize in designing, building, shipping and
                  scaling beautiful, usable products with blazing.
                </Typography>

                <Typography
                  variant="subtitle1"
                  fontSize={20}
                  sx={{
                    color: 'white',
                    width: '50%',
                    mb: 30
                  }}
                >
                  eSIMs, or embedded SIM cards, have the potential to cater to a
                  wide range of industries due to their flexibility and remote
                  programmability.
                </Typography>

                <Typography
                  variant="caption"
                  sx={{
                    color: 'white',
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px'
                  }}
                >
                  2024 © Ventel
                </Typography>
              </Box>
            </SidebarContent>
          </Scrollbar>
        </SidebarWrapper>
      </Content>
    </>
  );
}

export default LoginCover;
