import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const TermsOfUse = Loader(lazy(() => import('src/TermsOfUse')));
const PrivacyPage = Loader(lazy(() => import('src/PrivacyPage')));

const publicRoutes = [
  { path: 'term-of-use', element: <TermsOfUse /> },
  { path: 'privacy', element: <PrivacyPage /> },
];

export default publicRoutes;
