import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const TransactionReportModule = new Module({
  id: 'transactions-report',
  name: 'Transactions',
  path: 'transactions',
  Component: ComponentLoader(
    lazy(() => import('src/content/Report/TransactionReport/View'))
  ),
  // actions: {
  //   viewDetails: {
  //     id: 'view-details',
  //     name: 'viewDetails',
  //     label: 'View Details'
  //   },
  //   viewDaily: {
  //     id: 'view-daily',
  //     name: 'viewDaily',
  //     label: 'View Daily'
  //   },
  //   viewOverview: {
  //     id: 'view-overview',
  //     name: 'viewOverview',
  //     label: 'View Overview'
  //   }
  // }
});

const TransactionReportModules = [TransactionReportModule];

export default new ModuleBundle('TransactionsReport', TransactionReportModules);
