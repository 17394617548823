import Authenticated from 'src/components/Authenticated';

import BaseLayout from 'src/layouts/BaseLayout';
import { Navigate } from 'react-router';

// import TopNavigationLayout from 'src/layouts/TopNavigationLayout';
import SideBarNavigation from 'src/layouts/AccentSidebarLayout';
import registerModulesWithRouter from 'src/config/AccessControl/registerModulesWithRouter';
import DashboardModules from 'src/content/Dashboard/module';
import DashboardModulesv2 from 'src/content/Dashboardv2/module';
import SimInventoryModule from 'src/content/Inventory/Sim/module';
import DeviceInventoryModule from 'src/content/Inventory/Device/module';
import TicketsModule from 'src/content/Tickets/module';
import SIMDetailsModule from 'src/content/SIMDetails/module';
import JobModule from 'src/content/Job/module';
import RequestModules from 'src/content/requests/Request/module';
import SimActionModules from 'src/content/SimBulkActions/module';
import SIMDiagnosisModule from 'src/content/SIMDiagnosis/module';
import WorkCalendarModule from 'src/content/WorkCalendar/module';
import WalletModules from 'src/content/Wallet/module';
import statusRoutes from './base';
import authRoutes from './auth';
import managementRoutes from './management';
import requestsRoutes from './requests';
import whiteListingRoutes from './whiteListing';
import ReportRoutes from './reports';
import publicRoutes from './publicRoute';

const router = [
  {
    path: 'auth',
    children: authRoutes
  },
  {
    path: 'public',
    children: publicRoutes
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: statusRoutes
  },
  {
    path: '',
    element: (
      <Authenticated>
        <SideBarNavigation />
      </Authenticated>
    ),
    children: [
      { path: '/', element: <Navigate to="/dashboard" /> },
      registerModulesWithRouter(DashboardModules),
      registerModulesWithRouter(DashboardModulesv2),
      registerModulesWithRouter(SimActionModules),
      registerModulesWithRouter(SimInventoryModule),
      registerModulesWithRouter(SIMDiagnosisModule),
      registerModulesWithRouter(DeviceInventoryModule),
      registerModulesWithRouter(TicketsModule),
      registerModulesWithRouter(JobModule),
      registerModulesWithRouter(WorkCalendarModule),
      registerModulesWithRouter(RequestModules),
      registerModulesWithRouter(SIMDetailsModule),
      registerModulesWithRouter(WalletModules ) 
    ]
  },
  {
    path: '',
    element: (
      <Authenticated>
        <SideBarNavigation />
      </Authenticated>
    ),
    children: [
      {
        path: '/requests',
        children: requestsRoutes
      }
    ]
  },
  {
    path: '',
    element: (
      <Authenticated>
        <SideBarNavigation />
      </Authenticated>
    ),
    children: [
      {
        path: '/management',
        children: managementRoutes
      }
    ]
  },
  {
    path: '',
    element: (
      <Authenticated>
        <SideBarNavigation />
      </Authenticated>
    ),
    children: [
      {
        path: '/white-listing',
        children: whiteListingRoutes
      }
    ]
  },
  {
    path: '',
    element: (
      <Authenticated>
        <SideBarNavigation />
      </Authenticated>
    ),
    children: [
      {
        path: '/report',
        children: ReportRoutes
      }
    ]
  }
];

export default router;
